/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ptz-app-primary: mat.define-palette(mat.$blue-grey-palette, 900);
$ptz-app-accent:  mat.define-palette(mat.$teal-palette, 500, A100, A400);

// The warn palette is optional (defaults to red).
$ptz-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ptz-app-theme: mat.define-light-theme($ptz-app-primary, $ptz-app-accent, $ptz-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ptz-app-theme);

@mixin lms-theme($ptz-app-theme) {
    // button
    .mat-raised-button, .mat-flat-button, .mat-stroked-button {
        border-radius: 50px !important;
    }
    .mat-button-base {
        font-weight: 400;
        .mat-icon {
            &:first-child:not(:last-child) {
                margin-right: 6px;
            }
        }
        &[routerLink].active:after {
            position: absolute;
            content: '';
            border-bottom: 4px solid white;
            width: 100%;
            transform: translateX(-50%);
            bottom: -14px;
            left: 50%;
        }
    }
    .mat-snack-bar-container {
        .mat-simple-snackbar-action {
            color: #ffffff;
        }
    }
    .mat-snack-bar-container.error {
        background: #8e0000;
        color: #ffffff;
    }

    .mat-dialog-container {
        max-height: 90vh !important;
    }
    .no-padding .mat-dialog-container {
        padding: 0;
    }

    .cdk-overlay-pane {
        max-width: 95vw !important;
    }
}

@include lms-theme($ptz-app-theme);

body, html {
    margin: 0;
    height: 100%;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
    outline: #333 solid 1px;
}

.menu-background {
    background-color: #f2f2f2;
}

@import '~firebaseui/dist/firebaseui.css';

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
.ngx-datatable.material {
    box-shadow: none;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.4rem 1.2rem;
    align-self: center;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell.text-center {
    text-align: center !important;
}
.ngx-datatable.material .datatable-header-cell {
    text-align: center !important;
}
.ngx-datatable.material .empty-row {
    text-align: center;
    padding: 2rem 1.2rem;
    height: 100px;
}

@import "~@ng-select/ng-select/themes/material.theme.css";
ng-select .ng-input {
    padding: 0 .75em 0 .75em;
}
ng-select .ng-value {
    padding-top: 10px;
}
ng-select .ng-select-container.ng-appearance-outline {
    min-height: 54px !important;
}
ng-select .ng-select-container.ng-appearance-outline:after {
    width: calc(100% - 4px) !important;
}
ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #263238;
}

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
.slick-prev {
    left: 10px;
}
.slick-next {
    right: 10px;
}
.slick-prev:before, .slick-next:before {
    color: #263238;
    font-size: 30px;
}